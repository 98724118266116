<template>

    <div class="h-auto rounded-lg flex flex-col overflow-hidden bg-filters relative">
        <loader :loading="loadingNer && loadingNerY1" />
        <div class="h-auto py-2 flex flex-row justify-between items-center px-2">
            <span class="text-font-gray font-semibold text-xs">ANSR</span>
            <i v-if=" ner.objective_percentage > 99&&isNotNaAndTbd(ner.objective_percentage)" class="mdi mdi-arrow-top-right text-success text-aux text-xs
                            cursor-pointer"></i>
            <i v-else-if="isNotNaAndTbd(ner.objective_percentage)" class="mdi mdi-arrow-bottom-left text-danger text-aux text-xs"></i>
        </div>
        <div class="h-40 flex flex-row justify-center items-center">
            <chart-gauge :data="[ner.objective_percentage]">
                <div class="h-auto flex flex-col justify-center items-center">
                    <span class="text-white font-semibold text-lg">
                        {{ ner.ner || 0 | reduceBigNumbers(2) }} €
                    </span>
                    <span class="text-3xs text-font-gray mt-2">vs Obj</span>
                    <span class="font-semibold text-xxs"
                          :class="successOrDangerClass(ner_y1.objective_percentage,100)"
                    ><span v-if="ner.objective_percentage>0">+</span>{{ ner.objective_percentage || 0 | numberFormat(2) }}
                      <span v-if="isNotNaAndTbd(ner.objective_percentage)">%</span>
                    </span>
                    <span class="text-font-gray text-3xs mt-1">vs Forecast</span>
                    <span class="font-semibold text-xxs">
                       <span v-if="ner_y1.total>0">+</span>{{ ner_y1.total || 0 | reduceBigNumbers(2) }} €
                    </span>
                </div>
            </chart-gauge>
        </div>
    </div>

</template>

<script>
    import { state } from '@/store';

    export default {
        data() {
            return{
                loadingNer: false,
                loadingNerY1: false,
                ner: {},
                ner_y1: {},
            }
        },
        methods: {
            preload() {
                this.loadingNer = true
            },
            load() {
                this.axios.get('projects_management/ner', { params: this.params} ).then(response => {
                    this.loadingNer = false
                    this.ner = response.data.data
                })
                this.loadingNerY1 = true
                this.axios.get('projects_management/ner_y1', { params: this.params} ).then(response => {
                    this.loadingNerY1 = false
                    this.ner_y1 = response.data
                })
            }
        },
        computed: {
            params() {
                let params = state.globalParams
                if (this.$route.params.catId) {
                    params = {...params, ...{ sectorAmbiciona: this.$route.params.catId }}
                }
                return params
            }
        },
        watch:{
            params() { this.load() }
        },
        mounted() {
            this.preload();
            if(this.params) {
                this.load();
            }
        }
    }
</script>